import React, { useState, useEffect } from "react";
import Header from "./header";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import jsPDF from 'jspdf';

function Success() {
  const location = useLocation();
  const { patientId } = location.state || {};
  const [userInfo, setUserInfo] = useState(null);
  const [xrayData, setXrayData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [zoomStyle, setZoomStyle] = useState({});
  const [isZoomed, setIsZoomed] = useState(false);

  const capitalizeName = (name) => {
    return name.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const formattedName = userInfo ? capitalizeName(userInfo.name) : '';

  const fetchXrayData = async () => {
    if (!patientId) return;
    try {
      const response = await axios.get(`https://sotyo.care/xray/getXrayData?patient_id=${patientId}`);
      setUserInfo(response.data.user_info);
      setXrayData(response.data.xray_data);
    } catch (error) {
      console.error('Error fetching X-ray data:', error);
    }
  };

  useEffect(() => {
    fetchXrayData();
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000); // 4 seconds

    return () => clearTimeout(timer);
  }, [patientId]);

  const handleMouseMove = (e) => {
    const image = e.currentTarget;
    const { left, top, width, height } = image.getBoundingClientRect();

    const x = e.clientX - left;
    const y = e.clientY - top;

    setZoomStyle({
      backgroundImage: `url(https://sotyo.care/xray/${xrayData[Object.keys(xrayData)[0]].annotated_img_url})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: `${width * 2}px ${height * 2}px`,
      backgroundPosition: `${-x * 2 + width / 2}px ${-y * 2 + height / 2}px`,
      width: `${width}px`,
      height: `${height}px`,
      position: 'absolute',
      border: '2px solid #ddd',
      borderRadius: '8px',
      pointerEvents: 'none',
      opacity: 1,
      zIndex: 10,
      top: '0',
      left: '0',
    });
  };

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
    setZoomStyle({ opacity: 0 });
  };

  const downloadPDF = async () => {
    const doc = new jsPDF();
  
    const addFooter = (doc) => {
      const currentDate = new Date().toLocaleDateString();
      const pageHeight = doc.internal.pageSize.height;
      const pageWidth = doc.internal.pageSize.width;
  
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Report generated by Sotyo AI`, 10, pageHeight - 20);
      doc.text(`Date: ${currentDate}`, pageWidth - doc.getTextWidth(`Date: ${currentDate}`) - 10, pageHeight - 20);
      doc.setDrawColor(137, 52, 135);
      doc.line(10, pageHeight - 25, pageWidth - 10, pageHeight - 25);
    };
  
    // Title and styling
    doc.setFont("helvetica", "bold");
    doc.setTextColor(137, 52, 135);
    doc.setFontSize(16);
    doc.text("Sotyo AI", 10, 20);
    doc.setDrawColor(137, 52, 135);
    doc.line(10, 25, 200, 25);
  
    // Patient Details Header
    doc.setFontSize(14);
    doc.text("Patient Details", 105, 30, { align: "center" });
  
    // Patient Info
    const patientDetails = [
      `Name: ${formattedName}`,
      `Email: ${userInfo?.email || 'N/A'}`,
      `Contact Number: ${userInfo?.contact_number || 'N/A'}`,
      `Predictions: ${xrayData[Object.keys(xrayData)[0]].predictions.join(", ")}`,
      `Case Status: ${xrayData[Object.keys(xrayData)[0]].case_status}`
    ];
  
    let y = 50;
    doc.setTextColor(0);
    patientDetails.forEach(detail => {
      const [label, value] = detail.split(": ");
      doc.setFont("helvetica", "bold");
      doc.text(label + ":", 20, y);
      doc.setFont("helvetica", "normal");
      doc.text(value, 60, y);
      y += 15;
    });
  
    addFooter(doc);
    doc.addPage();
  
    // X-ray Image
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("Predicted X-ray Image", 105, 20, { align: "center" });
  
    const imgSrc = `https://sotyo.care/xray/${xrayData[Object.keys(xrayData)[0]].annotated_img_url}`;
  
    try {
      const response = await fetch(imgSrc);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  
      const blob = await response.blob();
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const imgData = reader.result;
        doc.addImage(imgData, 'JPEG', 10, 30, 190, 190);
        addFooter(doc);
        doc.save("patient_report.pdf");
      };
  
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
      alert("Failed to download the PDF. Please check the image source or try again later.");
    }
  };
  
  
  return (
    <main className="flex-1 flex flex-col bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-fuchsia-800 to-blue-800">
      <Header />
      <div className="flex flex-col items-center justify-center flex-1 text-center m-4">
        <div className="w-full max-w-7xl px-8">
          <div className="max-w-full mx-auto p-8 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-center">Patient Details</h2>
            {loading ? (
              <div className="flex flex-col items-center justify-center">
                <p className="text-lg mb-4">Processing the image...</p>
                <div className="loader"></div>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Patient Details Section */}
                <div className="p-6 flex flex-col justify-between">
                  <div className="flex flex-col space-y-4">
                    {userInfo && (
                      <>
                        <div className="flex items-center justify-between">
                          <h3 className="text-gray-900 font-semibold">
                            Name: <span className="font-normal">{formattedName}</span>
                          </h3>
                        </div>
                        <div className="flex items-center justify-between">
                          <h3 className="text-gray-900 font-semibold">
                            Email: <span className="font-normal">{userInfo.email}</span>
                          </h3>
                        </div>
                        <div className="flex items-center justify-between">
                          <h3 className="text-gray-900 font-semibold">
                            Contact Number: <span className="font-normal">{userInfo.contact_number}</span>
                          </h3>
                        </div>
                        <div className="flex items-center justify-between">
                          {xrayData && Object.keys(xrayData).length > 0 && (
                            <div>
                              <div className="flex items-center justify-between">
                                <h3 className="text-gray-900 font-semibold">Predictions:</h3>
                              </div>
                              <ul className="list-disc list-inside">
                                {xrayData[Object.keys(xrayData)[0]].predictions.map((prediction, index) => (
                                  <li key={index}>{prediction}</li>
                                ))}
                              </ul>
                              <div className="flex items-center justify-between mt-3">
                                <h3 className="text-gray-900 font-semibold">Case Status: &nbsp;
                                  <span className={`font-normal ${xrayData[Object.keys(xrayData)[0]].case_status === "ABNORMAL" ? 'text-red-500' : 'text-green-500'}`}>
                                    {xrayData[Object.keys(xrayData)[0]].case_status}
                                  </span>
                                </h3>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {/* Full-Width Download Button */}
                  <div className="mt-4">
                    <button 
                      className="w-full bg-zinc-900 text-white font-bold py-2 px-4 rounded"
                      onClick={downloadPDF}
                    >
                      Download Report
                    </button>
                  </div>
                </div>
                {/* X-ray Image Section */}
                <div className="flex flex-col items-center justify-center relative">
                  {xrayData && Object.keys(xrayData).length > 0 && (
                    <div className="relative" id="xray-image">
                      <img
                        src={`https://sotyo.care/xray/${xrayData[Object.keys(xrayData)[0]].annotated_img_url}`}
                        alt="Annotated X-Ray"
                        className="w-full h-auto rounded-md border-2 border-gray-300"
                        style={{ maxHeight: '700px' }}
                        onMouseMove={handleMouseMove}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />
                      {isZoomed && (
                        <div style={zoomStyle} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Success;
