import "./App.css";
import Footer from "./components/footer";
import Home from "./components/home";
import Success from "./components/sucess"; // Fixed import name
import UploadXray from "./components/upload_xray";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer } from "react-toastify"; // Import Toastify
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [patientAdded, setPatientAdded] = useState(false); // State to track if patient is added

  return (
    <>
      <BrowserRouter>
        <ToastContainer /> {/* Toast notifications */}
        <div className="flex h-screen flex-col">
          <Routes>
            <Route path="/" element={<Home setPatientAdded={setPatientAdded} />} />
            <Route 
              path="/upload-xray" 
              element={patientAdded ? <UploadXray setPatientAdded={setPatientAdded} /> : <Navigate to="/" />} 
            />
            <Route path="/success" element={<Success />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
