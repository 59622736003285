import React, { useState } from "react";
import { FiUpload } from "react-icons/fi";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from "./header";
import axios from 'axios';
import { toast } from 'react-toastify';

function UploadXray({ setPatientAdded }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { patientId } = location.state || {};
  const [formData, setFormData] = useState({
    image: null,
  });
  const [uploadError, setUploadError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const handleRemoveImage = () => {
    setFormData({
      ...formData,
      image: null,
    });
    setUploadError('');
  };

  const handleUpload = async () => {
    if (!formData.image) {
      setUploadError('Please select an image to upload.');
      return;
    }

    setIsLoading(true); // Set loading to true

    const form = new FormData();
    form.append('file', formData.image);

    try {
      await axios.post(`https://sotyo.care/xray/uploadXray?patient_id=${patientId}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setPatientAdded(false); // Reset the patient state
      toast.success('Image uploaded successfully!'); // Show success toast
      navigate('/success', { state: { patientId } }); // Navigate to success page
    } catch (error) {
      console.error('Error uploading the image:', error);
      setUploadError('Error uploading the image. Please try again.');
    } finally {
      setIsLoading(false); // Set loading back to false
    }
  };

  return (
    <>
      <main className="flex flex-col bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-fuchsia-800 to-blue-800">
        <Header />
      </main>
      <div className="flex flex-col items-center justify-center flex-1 text-center m-4">
        <div className="w-full max-w-md px-4">
          <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg">
            <h2 className="text-xl font-bold mb-4 text-center">
              Upload X-Ray Image
            </h2>
            {patientId ? (
              <div className="mb-4">
                <p>Your Patient ID is: <strong>{patientId}</strong></p>
              </div>
            ) : (
              <p>No Patient ID provided. Please go back and try again.</p>
            )}
            <form className="space-y-6">
              <div className="flex items-center justify-between">
                <label
                  htmlFor="image"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Upload Chest X-Ray<span className="text-red-500">*</span>
                </label>
              </div>

              {formData.image ? (
                <div className="flex items-center justify-between border border-gray-300 rounded-md p-3 mt-2">
                  <img
                    src={URL.createObjectURL(formData.image)}
                    alt="Preview"
                    className="w-16 h-16 object-cover rounded-md mr-2"
                  />
                  <span className="text-gray-900">{formData.image.name}</span>
                  <button
                    type="button"
                    onClick={handleRemoveImage}
                    className="text-red-500 hover:text-red-700"
                  >
                    &times; {/* Cross icon */}
                  </button>
                </div>
              ) : (
                <div className="mt-6 border-dashed border-2 border-gray-300 rounded-md flex flex-col items-center justify-center py-10">
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  <label
                    htmlFor="image"
                    className="flex flex-col items-center cursor-pointer"
                  >
                    <FiUpload className="text-2xl mb-2" />
                    <span className="text-gray-500">Upload X-Ray Image</span>
                    <span className="text-gray-400 text-sm">
                      Click or drag to upload
                    </span>
                  </label>
                </div>
              )}
              {uploadError && <div className="text-red-500">{uploadError}</div>}
              <div>
                <button
                  type="button"
                  onClick={handleUpload}
                  disabled={isLoading} // Disable button when loading
                  className={`flex w-full justify-center rounded-md ${isLoading ? 'bg-gray-400' : 'bg-zinc-900'} px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                  {isLoading ? 'Uploading...' : 'Upload'} {/* Show loading text */}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadXray;
