import React from 'react'
import {Link } from 'react-router-dom';

function Header() {
  return (
    <div>
      <nav className="navbar p-2">
  <div className="container-fluid mx-auto flex">
    <Link className="navbar-brand" to="/">
      {/* <img src={logoSotyo} alt="Sotyo Ai" width="100" height="240" /> */}
      <h1 className='text-white font-bold ml-6' style={{fontSize:'2rem'}}>Sotyo AI</h1>
    </Link>
  </div>
</nav>;
    </div>
  )
}

export default Header

